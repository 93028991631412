import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description:此文件用于放置侧边栏页面
 * **/

export var sidebarRouter = [{
  path: '/wechat-group-task',
  component: function component() {
    return import('@/views/sidebar/material/wechat-group-task.vue');
  },
  meta: {
    title: '群聊执行任务',
    keepAlive: false
  }
}];