/**
 * @description:此配置文件，用于配置各个不同企微主体(目前已顶呱呱作为主要企微主体)
 * 用于配置企微需要手动授权后的企微主体（顶呱呱之前都是静默授权）
 * 用于产线配置
 * **/
var wrchatCropIdList = [{
  name: '浙江良驹律师事务所',
  corpId: 'wwbcc57ce621e6e190',
  url: 'https://m-crm.zjljls.cn',
  tUrl: 'https://tm-crm.zjljls.cn',
  pUrl: 'https://pm-crm.zjljls.cn'
}, {
  name: '北京顶言律师事务所',
  corpId: 'wwbd1d249b93791ce3',
  url: 'https://m-crm.bjdyls.cn',
  tUrl: 'https://tm-crm.bjdyls.cn',
  pUrl: 'https://pm-crm.bjdyls.cn'
}, {
  name: '广东莞升律师事务所',
  corpId: 'wwee210e03e874d70c',
  url: 'https://m-crm.gdgsls.cn',
  tUrl: 'https://tm-crm.gdgsls.cn',
  pUrl: 'https://pm-crm.gdgsls.cn'
}, {
  name: '重庆盈兴律师事务所',
  corpId: 'ww4e8105e5522f252d',
  url: 'https://m-crm.cqyxls.cn',
  tUrl: 'https://tm-crm.cqyxls.cn',
  pUrl: 'https://pm-crm.cqyxls.cn'
}, {
  name: '四川跃升（郑州）律师事务所',
  corpId: 'ww49c80104f60061ac',
  url: 'https://m-crm.scyszzls.cn',
  tUrl: 'https://tm-crm.scyszzls.cn',
  pUrl: 'https://pm-crm.scyszzls.cn'
}, {
  name: '上海顶锦律师事务所',
  corpId: 'wwc0438bb191697961',
  url: 'https://m-crm.shdjls.cn',
  tUrl: 'https://tm-crm.shdjls.cn',
  pUrl: 'https://pm-crm.shdjls.cn'
}, {
  name: '湖南顶璟律师事务所',
  corpId: 'wwe4df9dbbb2eeb72f',
  url: 'https://m-crm.hndjls.cn',
  tUrl: 'https://tm-crm.hndjls.cn',
  pUrl: 'https://pm-crm.hndjls.cn'
}, {
  name: '宜昌跃升法律咨询有限公司',
  corpId: 'ww80e011c5697f92d0',
  url: 'https://m-crm.ycysls.cn',
  tUrl: 'https://tm-crm.ycysls.cn',
  pUrl: 'https://pm-crm.ycysls.cn'
}, {
  name: '四川跃升律师事务所',
  corpId: 'wwbe98805c1d367f49',
  url: 'https://m-crm.scysls.cn',
  tUrl: 'https://tm-crm.scysls.cn',
  pUrl: 'https://pm-crm.scysls.cn'
}, {
  name: '湖北灵均律师事务所',
  corpId: 'ww164c12ed9aa8da6e',
  url: 'https://m-crm.hbljls.cn',
  tUrl: 'https://tm-crm.hbljls.cn',
  pUrl: 'https://pm-crm.hbljls.cn'
}, {
  name: '广东顶泽律师事务所',
  corpId: 'ww072c0283fa65f417',
  url: 'https://m-crm.gddzls.cn',
  tUrl: 'https://tm-crm.gddzls.cn',
  pUrl: 'https://pm-crm.gddzls.cn'
}, {
  name: '广东弘胤律师事务所',
  corpId: 'ww83cf67d2884e5d33',
  url: 'https://m-crm.gdhyls.cn',
  tUrl: 'https://tm-crm.gdhyls.cn',
  pUrl: 'https://pm-crm.gdhyls.cn'
}, {
  name: '广东顶匠律师事务所',
  corpId: 'ww0af94d0e65019e56',
  url: 'https://m-crm.gddjlssws.cn',
  tUrl: 'https://tm-crm.gddjlssws.cn',
  pUrl: 'https://pm-crm.gddjlssws.cn'
}];
module.exports = wrchatCropIdList;